<template>
  <span class="nursery-remove-off-day-modal">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="!isValid"
      ok-variant="primary"
      ok-title="Enregistrer"
      @show="init()"
    >
      <template v-slot:modal-title>
        <i class="fa fa-calendar-mark"></i> Supprimer un jour de fermeture
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="day">
        <div v-if="day.holiday">
          <div v-if="day.presence">
            <span v-if="sameHolidays">
              Voulez-vous marquer la période {{ day.comments }}
              comme jours de fermeture?
            </span>
            <span v-else>
              Voulez-vous marquer le {{ day.date | dateToString('ddd ll') }}
              <span v-if="day.comments">({{ day.comments }})</span>
              comme un jour de fermeture?
            </span>
          </div>
          <div v-else>
            <span v-if="sameHolidays">
              Voulez-vous marquer la période {{ day.comments }}
              comme jours d'ouverture?
            </span>
            <span v-else>
              Voulez-vous marquer le {{ day.date | dateToString('ddd ll') }}
              <span v-if="day.comments">({{ day.comments }})</span>
              comme un jour d'ouverture?
            </span>
          </div>
        </div>
        <div v-else>
          Voulez-vous supprimer le {{ day.date | dateToString('ddd ll') }}
          <span v-if="day.comments">({{ day.comments }})</span>?
        </div>
        <b-row v-if="day.holiday && day.comments">
          <b-col>
            <b-form-group
              label-for="sameHolidays"
              description="Toutes les journées de cette période seront aussi modifiées"
            >
              <b-checkbox
                id="sameHolidays"
                v-model="sameHolidays"
              >
                Toute la période {{ day.comments }}
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import { makeNurseryOffDay } from '@/types/nursery'
import { BackendApi } from '@/utils/http'

export default {
  name: 'NurseryDeleteOffDayModal',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    day: Object,
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sameHolidays: false,
      errorText: '',
    }
  },
  watch: {
  },
  computed: {
    modalId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-nursery-delete-off-day'
      }
    },
    isValid() {
      return (this.day)
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    async onConfirm(event) {
      event.preventDefault()
      this.errorText = ''
      if (this.isValid) {
        try {
          const url = '/nursery/api/off-day-detail/' + this.day.id + '/'
          const backendApi = new BackendApi('post', url)
          const data = {
            'same_holidays': this.sameHolidays,
          }
          const resp = await backendApi.callApi(data)
          this.$emit('save', {})
          let text = 'Le ' + dateToString(this.day, 'ddd ll') + ' a été supprimée'
          if (this.day.holiday) {
            if (this.sameHolidays) {
              if (this.day.presence) {
                text = this.day.comments + ' est une période de fermeture'
              } else {
                text = this.day.comments + ' est une période d\'ouverture'
              }
            } else {
              if (this.day.presence) {
                text = 'Le ' + dateToString(this.day.date, 'ddd ll') + ' est un jour de fermeture'
              } else {
                text = 'Le ' + dateToString(this.day.date, 'ddd ll') + ' est un jour d\'ouverture'
              }
            }
          }
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    onCancel() {
    },
    init() {
    },
  },
}
</script>
<style lang="less" scoped>
</style>
