<template>
  <span class="nursery-add-off-day-modal">
    <b-modal
      :id="modalId"
      cancel-title="Annuler"
      @ok="onConfirm"
      @cancel="onCancel"
      :ok-disabled="!isValid"
      ok-variant="primary"
      ok-title="Enregistrer"
      @show="init()"
    >
      <template v-slot:modal-title v-if="nursery">
        <i class="fa fa-calendar-mark"></i> Ajouter un jour de fermeture
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div>
        <b-row>
          <b-col>
            <b-form-group
              label-for="date"
              label="Jour de fermeture"
            >
              <b-input
                type="date"
                id="date"
                v-model="date"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <br />
            <b-form-checkbox id="fullDay" v-model="fullDay">
              Journée entière
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row v-if="!fullDay">
          <b-col>
            <b-form-group label-for="startTime" label="Heure de début">
              <b-input
                type="time"
                id="startTime"
                v-model="startTime"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label-for="endTime" label="Heure de fin">
              <b-input
                type="time"
                id="endTime"
                v-model="endTime"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label-for="comments"
              label="Commentaires"
            >
              <b-input type="text" v-model="comments"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { dateToString } from '@/filters/texts'
import { makeNurseryOffDay } from '@/types/nursery'
import { BackendApi } from '@/utils/http'

export default {
  name: 'NurseryAddOffDayModal',
  components: {
  },
  mixins: [BackendMixin],
  props: {
    nursery: Object,
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      date: '',
      fullDay: true,
      startTime: '',
      endTime: '',
      errorText: '',
      comments: '',
    }
  },
  watch: {
  },
  computed: {
    modalId() {
      if (this.id) {
        return this.id
      } else {
        return 'bv-nursery-add-off-day'
      }
    },
    isValid() {
      if (!this.date) {
        return false
      }
      if (!this.fullDay && (!this.startTime || !this.endTime)) {
        return false
      }
      return true
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    async onConfirm(event) {
      event.preventDefault()
      this.errorText = ''
      if (this.isValid) {
        try {
          const url = '/nursery/api/off-days/' + this.nursery.id + '/'
          const backendApi = new BackendApi('post', url)
          const data = {
            'date': dateToString(this.date, 'YYYY-MM-DD'),
            'start_time': this.fullDay ? null : this.startTime,
            'end_time': this.fullDay ? null : this.endTime,
            'comments': this.comments,
          }
          const resp = await backendApi.callApi(data)
          const offDay = makeNurseryOffDay(resp.data)
          this.$emit('save', { offDay: offDay, })
          let text = 'La journée a été ajoutée'
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    onCancel() {
    },
    init() {
    },
  },
}
</script>
<style lang="less" scoped>
</style>
