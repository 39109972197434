<template>
  <div class="nursery-off-days" v-if="canAccess">
    <page-header
      title="Jours de fermeture Crèche"
      icon="fa fa-baby-carriage"
      :links="getLinks()"
    ></page-header>
    <div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div ref="printMe" v-if="!isLoading(loadingName) && selectedNursery">
        <b-row>
          <b-col><b>{{ selectedNursery.name }}</b></b-col>
          <b-col class="text-right">
            <b-checkbox id="showPresence" v-model="showPresence">
              Voir les jours d'ouverture sur les vacances
            </b-checkbox>
          </b-col>
        </b-row>
        <div v-if="offDays.length === 0" class="empty-text">
          Aucun jour de fermeture
        </div>
        <div v-if="offDays.length" ref="excelTable">
          <b-row>
            <b-col>
              <table class="table small-text table-striped table-bordered">
                <tr>
                  <th>Jour</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr
                  v-for="day of filteredOffDays"
                  :key="day.id"
                  :class="{ presence: day.presence }"
                >
                  <td>{{ day.date | dateToString('ddd LL') }}</td>
                  <td>
                    <span v-if="day.startTime || day.endTime">
                      {{ displayHourMin(day.startTime) }} - {{ displayHourMin(day.endTime) }}
                    </span>
                    <span v-else>
                      Journée entière
                    </span>
                  </td>
                  <td>{{ day.comments }}</td>
                  <td style="min-width: 100px;">
                    <span v-if="day.presence">Ouvert</span>
                  </td>
                  <td>
                    <span v-if="canDelete" class="no-excel">
                      <a href @click.prevent="showDeleteOffDay(day)">
                        <i class="fa fa-edit" v-if="day.holiday"></i>
                        <i class="fa fa-trash" v-else></i>
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <confirm-modal
      name="build-off-days"
      title="Charger les jours depuis les vacances scolaires"
      text="Les jours de fermeture seront basés sur les vacances scolaires"
      @confirmed="onBuildOffDays"
    >
    </confirm-modal>
    <nursery-add-off-day-modal
      :nursery="selectedNursery"
      id="bv-nursery-add-off-day"
      @save="loadData()"
    ></nursery-add-off-day-modal>
    <nursery-delete-off-day-modal
      :day="selectedDay"
      id="bv-nursery-delete-off-day"
      @save="loadData()"
    ></nursery-delete-off-day-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment/moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import NurseryAddOffDayModal from '@/components/Nursery/NurseryAddOffDayModal.vue'
import NurseryDeleteOffDayModal from '@/components/Nursery/NurseryRemoveOffDayModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { makeNursery, makeNurseryOffDay } from '@/types/nursery'
import { displayHourMin } from '@/utils/time'

export default {
  name: 'NurseryOffDays',
  mixins: [BackendMixin],
  components: {
    NurseryDeleteOffDayModal,
    NurseryAddOffDayModal,
    ConfirmModal,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'NurseryOffDays',
      nurseries: [],
      offDays: [],
      selectedNursery: null,
      showPresence: false,
      selectedDay: null,
    }
  },
  watch: {
    selectedNursery: function() {
      this.loadData()
    },
  },
  computed: {
    canAccess() {
      return this.hasPerm('nursery.view_nurseryoffday')
    },
    canAdd() {
      return this.hasPerm('nursery.change_nurseryoffday')
    },
    canChange() {
      return this.hasPerm('nursery.change_nurseryoffday')
    },
    canDelete() {
      return this.hasPerm('nursery.delete_nurseryoffday')
    },
    isActive() {
      return this.startDate && this.endDate
    },
    filteredOffDays() {
      if (this.showPresence) {
        return this.offDays
      } else {
        return this.offDays.filter(elt => !elt.presence)
      }
    },
  },
  async mounted() {
    await this.loadNurseries()
  },
  methods: {
    displayHourMin,
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    async loadNurseries() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(makeNursery)
        if (this.nurseries.length === 1) {
          this.selectedNursery = this.nurseries[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadData() {
      this.offDays = []
      if (this.selectedNursery) {
        this.startLoading(this.loadingName)
        let url = '/nursery/api/off-days/' + this.selectedNursery.id + '/?holidays=1'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.offDays = resp.data.map(makeNurseryOffDay)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    showAdd() {
      this.$bvModal.show('bv-nursery-add-off-day')
    },
    showBuildOfDays() {
      this.$bvModal.show('bv-confirm-modal:build-off-days')
    },
    showDeleteOffDay(day) {
      this.selectedDay = day
      this.$bvModal.show('bv-nursery-delete-off-day')
    },
    async onBuildOffDays() {
      await this.postBuildOffDays()
      await this.loadData()
    },
    async onOffDayDeleted() {
      this.selectedDay = null
      await this.loadData()
    },
    async postBuildOffDays() {
      this.offDays = []
      this.startLoading(this.loadingName)
      let url = '/nursery/api/build-off-days/' + this.selectedNursery.id + '/'
      const backendApi = new BackendApi('post', url)
      try {
        await backendApi.callApi({})
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'jours-de-fermeture-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'jours-de-fermeture-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const isReady = (!this.isLoading(this.loadingName)) && this.selectedNursery
      const links = [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (isReady && this.offDays.length) ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (isReady && this.offDays.length) ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
      if (this.canAdd) {
        links.push(
          {
            id: 3,
            label: 'Vacances scolaires',
            callback: this.showBuildOfDays,
            icon: 'fa fa-clock',
            cssClass: (!isReady) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      if (this.canChange) {
        links.push(
          {
            id: 4,
            label: 'Ajouter',
            callback: this.showAdd,
            icon: 'fa fa-plus',
            cssClass: (!isReady) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
  },
}
</script>

<style lang="less" scoped>
tr.presence td {
  background: #f5f8ba !important;
}
</style>
